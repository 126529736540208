import { motion } from "framer-motion";
import React, { useState } from "react";
import { createSlideAnimation } from "../animations/animations";
import CSRImage1 from "../assets/csr-events-3/csr-1.jpg";
import CSRImage2 from "../assets/csr-events-3/csr-2.jpeg";
import CSRImage3 from "../assets/csr-events-3/csr-3.jpeg";
import CSRImage4 from "../assets/csr-events-3/csr-4.jpeg";
import CSRImage5 from "../assets/csr-events-3/csr-5.jpeg";
import CSRImage6 from "../assets/csr-events-3/csr-6.jpeg";
import CSRImage7 from "../assets/csr-events-3/csr-7.jpg";
import CSRImage8 from "../assets/csr-events-3/csr-8.jpeg";
import CSRImage9 from "../assets/csr-events-3/csr-9.jpeg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { IoMdClose, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Swiper as SwiperType } from "swiper";

const ImageDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  imageSrc: string;
  allImages: string[];
}> = ({ isOpen, onClose, imageSrc, allImages }) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const initialSlide = allImages.indexOf(imageSrc);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 p-4 cursor-pointer px-2">
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[79vh] overflow-hidden relative">
        <button
          onClick={() => swiper?.slidePrev()}
          className="hidden md:block absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-gray-400 bg-opacity-50 hover:bg-opacity-75 rounded-full p-3 text-white transition-colors duration-200"
          aria-label="Previous image"
        >
          <IoIosArrowBack size={24} />
        </button>
        <button
          onClick={() => swiper?.slideNext()}
          className="hidden md:block absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-gray-400 bg-opacity-50 hover:bg-opacity-75 rounded-full p-3 text-white transition-colors duration-200"
          aria-label="Next image"
        >
          <IoIosArrowForward size={24} />
        </button>

        <Swiper
          initialSlide={initialSlide}
          loop={true}
          className=""
          onSwiper={setSwiper}
        >
          {allImages.map((img, index) => (
            <SwiperSlide key={index} className="">
              <div className="flex items-center justify-center">
                <img
                  src={img}
                  alt={`Enlarged view ${index + 1}`}
                  className="w-full h-full max-h-[79vh] object-cover"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-white bg-gray-400 bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 transition-colors duration-200 z-10"
          aria-label="Close"
        >
          <IoMdClose size={24} />
        </button>
      </div>
    </div>
  );
};

const CSREvents3: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const allImages = [
    CSRImage1,
    CSRImage2,
    CSRImage3,
    CSRImage4,
    CSRImage5,
    CSRImage6,
    CSRImage7,
    CSRImage8,
    CSRImage9,
  ];

  return (
    <div className="pb-14 md:pb-20 ">
      <div className="px-5 md:px-11">
        <div className=" py-2 flex flex-col md:flex-row ">
          <motion.div
            className="md:w-1/2 md:h-2/3 mb-8 md:mb-0"
            {...createSlideAnimation("left", 0.2)}
          >
            <div className="mb-4">
              <img
                src={CSRImage1}
                alt="Key Moment"
                className="w-full h-auto rounded-lg shadow-lg cursor-pointer"
                onClick={() => setSelectedImage(CSRImage1)}
              />
            </div>
            <div className="mt-4 shadow-md cursor-pointer">
              <Swiper
                modules={[Autoplay]}
                slidesPerView={3}
                spaceBetween={10}
                autoplay={{ delay: 2000, disableOnInteraction: false }}
                loop={true}
              >
                {[
                  CSRImage2,
                  CSRImage3,
                  CSRImage4,
                  CSRImage5,
                  CSRImage6,
                  CSRImage7,
                  CSRImage8,
                  CSRImage9,
                ].map((img, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={img}
                      alt={`Key Moment ${index + 2}`}
                      className="w-full h-[80px] sm:h-[80px] md:h-[80px] lg:h-[120px] 2xl:h-[180px] rounded-lg shadow-xl cursor-pointer
                        transition duration-300 ease-in-out transform hover:scale-95 "
                      onClick={() => setSelectedImage(img)}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </motion.div>
          <motion.div
            className="md:w-1/2 md:pl-8 mt-5 "
            {...createSlideAnimation("right", 0.2)}
          >
            <h3 className="text-xl sm:text-xl md:text-2xl lg:text-[30px] font-normal leading-tight sm:leading-snug md:leading-normal lg:leading-[36px] text-[#302c51] mb-10 font-prata">
              Value Wings achieves another Milestone with MITSUI & Co. India
            </h3>
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
              We extend our{" "}
              <span className="font-medium text-gray-700">
                heartfelt congratulations
              </span>{" "}
              to our candidates on successfully completing Value Wings'
              <span className="font-medium text-gray-700">
                {" "}
                first CSR initiative
              </span>
              , proudly sponsored by{" "}
              <span className="font-medium text-gray-700">
                Mitsui & Co. India Pvt Ltd.
              </span>
            </p>
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
              This achievement represents a{" "}
              <span className="font-medium text-gray-700">
                {" "}
                transformative step{" "}
              </span>{" "}
              in our mission to equip students from underprivileged backgrounds
              with{" "}
              <span className="font-medium text-gray-700">
                essential language skills and career opportunities.
              </span>
            </p>
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
              At Value Wings, we believe that{" "}
              <span className="font-medium text-gray-700">
                financial constraints should never hinder potential.
              </span>{" "}
              By providing{" "}
              <span className="font-medium text-gray-700">
                skill development, and career opportunities,
              </span>{" "}
              we empower individuals to overcome barriers and build promising
              futures.
            </p>
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
              This success{" "}
              <span className="font-medium text-gray-700">
                {" "}
                reinforces our commitment to bridging the gap between talent and
                opportunity,
              </span>{" "}
              ensuring that{" "}
              <span className="font-medium text-gray-700">
                financial challenges do not stand in the way of success.
              </span>
            </p>
          </motion.div>
        </div>

        <ImageDialog
          isOpen={!!selectedImage}
          onClose={() => setSelectedImage(null)}
          imageSrc={selectedImage || ""}
          allImages={allImages}
        />
      </div>
    </div>
  );
};

export default CSREvents3;
