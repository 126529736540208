import { motion } from "framer-motion";
import React from "react";
import { createSlideAnimation } from "../animations/animations";
import "swiper/css";
import "swiper/css/autoplay";
import { RiCalendarEventFill } from "react-icons/ri";

const CSREvents: React.FC = () => {
  return (
    <div className="">
      <div className="relative bg-[#1d1747e6] text-white py-28">
        <div className="text-center mb-12">
          <motion.h1
            className="font-prata font-normal text-[32px] md:text-[48px] leading-[58px]"
            {...createSlideAnimation("bottom", 0.2)}
          >
            Key Moments
          </motion.h1>
        </div>
        <div className="absolute w-[205px] h-[63px] bottom-0 left-6 md:left-11 transform translate-y-1/2 bg-white py-2 px-4 rounded shadow-md flex items-center justify-center">
          <span className="text-[#ff6b35] mr-2 text-[24px]">
            <RiCalendarEventFill />
          </span>
          <div className="w-px h-7 bg-[#EAEAEA] mx-4"></div>
          <span className="font-normal text-[16px] leading-[28px] text-[#1d1747e6] mt-2">
            CSR Events
          </span>
        </div>
      </div>
    </div>
  );
};

export default CSREvents;
