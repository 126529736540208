import { motion } from "framer-motion";
import React from "react";
import { GrMapLocation } from "react-icons/gr";
import { createSlideAnimation } from "../animations/animations";
import ManjuImage from "../assets/travel/manju.jpeg";

const CareerMilestones: React.FC = () => {
  return (
    <div className="pb-8 sm:pb-6 md:pb-12">
      {/* Top Section */}
      <div className="relative bg-[#2A2550] text-white py-16 sm:py-24">
        <div className="text-center mb-8 sm:mb-12">
          <motion.h1
            className="font-prata font-normal text-2xl sm:text-3xl md:text-4xl lg:text-[48px] leading-tight sm:leading-[58px]"
            {...createSlideAnimation("bottom", 0.2)}
          >
            Career Milestones
          </motion.h1>
        </div>

        <div className="absolute w-[180px] sm:w-[205px] h-[50px] sm:h-[63px] bottom-0 left-4 sm:left-6 md:left-11 transform translate-y-1/2 bg-white py-2 px-3 sm:px-4 rounded shadow-md flex items-center justify-center">
          <span className="text-[#ff6b35] mr-2 text-xl sm:text-[24px]">
            <GrMapLocation />
          </span>
          <div className="w-px h-6 sm:h-7 bg-[#EAEAEA] mx-3 sm:mx-4"></div>
          <span className="font-normal text-sm sm:text-[16px] leading-[24px] sm:leading-[28px] text-[#1d1747e6] mt-2">
            Milestones
          </span>
        </div>
      </div>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 my-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Left side - Image */}
          <motion.div
            className="relative"
            {...createSlideAnimation("left", 0.3)}
          >
            <img
              src={ManjuImage}
              alt="Career Milestone"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </motion.div>

          {/* Right side - Content */}
          <motion.div className="" {...createSlideAnimation("right", 0.4)}>
            <h2 className="font-prata text-2xl sm:text-3xl text-[#2A2550] mb-6">
              A Historic Milestone: VALUE WINGS' First CSR Candidate Lands in
              Japan!
            </h2>
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-2">
              With every journey, we celebrate not just a destination but a
              dream fulfilled!{" "}
            </p>{" "}
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-2">
              {" "}
              In March 2025, we mark a remarkable achievement as one of our
              dedicated candidate successfully lands in Japan, stepping into a
              future filled with opportunities.{" "}
            </p>{" "}
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-2">
              Her determination, hard work, and trust in VALUE WINGS made this
              moment possible, and we couldn't be prouder!{" "}
            </p>{" "}
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-2">
              Marking a special milestone, she is not just another success
              story—she is the{" "}
              <span className="font-medium text-gray-700">
                {" "}
                first Corporate Social Responsibility (CSR) candidate to achieve
                this feat!
              </span>
            </p>{" "}
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-">
              As we continue to open doors to global opportunities, this
              milestone reflects our strong commitment to transforming lives and
              shaping brighter futures.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default CareerMilestones;
