import React from "react";
import TopBar from "../component/TopBar";
import NavBar from "../component/NavBar";
import Footer from "../component/Footer";
import CSREvents from "../component/CSR-events";
import CSREvents2 from "../component/CSR-events2";
import { FaWhatsapp } from "react-icons/fa";
import CSREvents3 from "../component/CSR-events3";
import CSREvents1 from "../component/CSR-events1";
import CSREvents4 from "../component/CSR-events4";
const KeyMomentsPage: React.FC = () => {
  return (
    <div>
      <TopBar />
      <NavBar />
      <CSREvents />
      <div className="pt-28">
        <CSREvents4 />
        <CSREvents3 />
        <CSREvents2 />
        <CSREvents1 />
      </div>
      <Footer />
      <a
        href="https://wa.me/919035053109?text=Hi!%20I%27d%20like%20to%20know%20more%20about%20your%20programs"
        className="fixed bottom-6 right-6 bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition-all z-50"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Chat on WhatsApp"
      >
        <FaWhatsapp size={40} />
      </a>
    </div>
  );
};

export default KeyMomentsPage;
