import React, { useState } from "react";
import CSRImage1 from "../assets/csr-events-2/Csr-1.webp";
import CSRImage2 from "../assets/csr-events-2/Csr-2.webp";
import CSRImage3 from "../assets/csr-events-2/Csr-3.webp";
import CSRImage4 from "../assets/csr-events-2/Csr-4.webp";
import CSRImage5 from "../assets/csr-events-2/Csr-5.webp";
import CSRImage6 from "../assets/csr-events-2/Csr-6.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { IoMdClose, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { createSlideAnimation } from "../animations/animations";
import { motion } from "framer-motion";
import { Swiper as SwiperType } from "swiper";

const ImageDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  imageSrc: string;
  allImages: string[];
}> = ({ isOpen, onClose, imageSrc, allImages }) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const initialSlide = allImages.indexOf(imageSrc);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 p-4 cursor-pointer px-2">
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[79vh] overflow-hidden relative">
        <button
          onClick={() => swiper?.slidePrev()}
          className="hidden md:block absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-gray-400 bg-opacity-50 hover:bg-opacity-75 rounded-full p-3 text-white transition-colors duration-200"
          aria-label="Previous image"
        >
          <IoIosArrowBack size={24} />
        </button>
        <button
          onClick={() => swiper?.slideNext()}
          className="hidden md:block absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-gray-400 bg-opacity-50 hover:bg-opacity-75 rounded-full p-3 text-white transition-colors duration-200"
          aria-label="Next image"
        >
          <IoIosArrowForward size={24} />
        </button>

        <Swiper
          initialSlide={initialSlide}
          loop={true}
          className=""
          onSwiper={setSwiper}
        >
          {allImages.map((img, index) => (
            <SwiperSlide key={index} className="">
              <div className="flex items-center justify-center">
                <img
                  src={img}
                  alt={`Enlarged view ${index + 1}`}
                  className="w-full h-full max-h-[79vh] object-cover"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-white bg-gray-400 bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 transition-colors duration-200 z-10"
          aria-label="Close"
        >
          <IoMdClose size={24} />
        </button>
      </div>
    </div>
  );
};

const CSREvents2: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const allImages = [
    CSRImage1,
    CSRImage2,
    CSRImage3,
    CSRImage4,
    CSRImage5,
    CSRImage6,
  ];

  return (
    <div className="pb-14 md:pb-20 ">
      <div className="px-5 md:px-11">
        <div className="py-2 flex flex-col md:flex-row">
          <motion.div
            className="md:w-1/2 md:pr-8 mt-5 order-2 md:order-1 "
            {...createSlideAnimation("left", 0.2)}
          >
            <h3 className="text-xl sm:text-xl md:text-2xl lg:text-[30px] font-normal leading-tight sm:leading-snug md:leading-normal lg:leading-[36px] text-[#302c51] mb-10 font-prata">
              Mitsui Client Visit for Inspiring Our Students to Aim Higher
            </h3>
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
              As our training program progresses,{" "}
              <span className="font-medium text-gray-700">
                Mitsui delegates paid us another visit,
              </span>{" "}
              showing their genuine care and support for our students.
            </p>
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
              They spent time engaging with the students,{" "}
              <span className="font-medium text-gray-700">
                sharing valuable advice and words of encouragement.
              </span>{" "}
              Their message was simple yet powerful, stay focused, keep pushing
              forward, and give it your all.
            </p>
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
              <span className="font-medium text-gray-700">
                The heartfelt conversations left a lasting impact, filling the
                room with motivation and optimism.
              </span>{" "}
              Mitsui’s delegates reminded the students of their incredible
              potential and wished them success in their upcoming exams and
              future journeys.
            </p>
            <p className="text-[15px] leading-[28px] font-normal text-[#858585] mb-4">
              Moments like these remind us of the{" "}
              <span className="font-medium text-gray-700">
                strength that comes from support,
              </span>{" "}
              as we continue to guide our students toward brighter futures.
            </p>
          </motion.div>
          <motion.div
            className="md:w-1/2 mb-8 md:mb-0 order-1 md:order-2"
            {...createSlideAnimation("right", 0.2)}
          >
            <div className="mb-4">
              <img
                src={CSRImage1}
                alt="Key Moment"
                className="w-full h-full rounded-lg shadow-lg cursor-pointer"
                onClick={() => setSelectedImage(CSRImage1)}
              />
            </div>
            <div className="mt-4 shadow-md cursor-pointer">
              <Swiper
                modules={[Autoplay]}
                slidesPerView={3}
                spaceBetween={10}
                autoplay={{ delay: 2000, disableOnInteraction: false }}
                loop={true}
              >
                {[CSRImage2, CSRImage3, CSRImage4, CSRImage5].map(
                  (img, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={img}
                        alt={`Key Moment ${index + 2}`}
                        className="w-full h-[80px] sm:h-[80px] md:h-[80px] lg:h-[120px] 2xl:h-[160px] rounded-lg shadow-xl cursor-pointer
                        transition duration-300 ease-in-out transform hover:scale-95 "
                        onClick={() => setSelectedImage(img)}
                      />
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
          </motion.div>
        </div>

        <ImageDialog
          isOpen={!!selectedImage}
          onClose={() => setSelectedImage(null)}
          imageSrc={selectedImage || ""}
          allImages={allImages}
        />
      </div>
    </div>
  );
};

export default CSREvents2;
